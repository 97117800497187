import { Pipe, PipeTransform } from '@angular/core';

import { HelperService } from 'src/app/core/auth/services/helper.service';
import { ViewEvent } from 'src/app/shared/models';

@Pipe({
	name: 'viewEventsSort',
	standalone: true,
})
export class ViewEventsSortPipe implements PipeTransform {
	transform(viewEvents: ViewEvent[], eventTriggerId: number, dateSort: { text: string; column: string; direction: string }): ViewEvent[] {
		return HelperService.orderEvents(viewEvents, dateSort?.column, dateSort?.direction);
	}
}
